import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/jujia',
    name: 'jujia',
    component: () => import('../views/jujia.vue')
  },
  {
    path: '/jigou',
    name: 'jigou',
    component: () => import('../views/jigou.vue')
  },
  {
    path: '/price',
    name: 'price',
    component: () => import('../views/price.vue')
  },
  {
    path: '/product1',
    name: 'product1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/product1.vue')
  },
  {
    path: '/product2',
    name: 'product2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/product2.vue')
  },
  {
    path: '/programme1',
    name: 'programme1',
    component: () => import('../views/programme1.vue')
  }
  ,
  {
    path: '/programme2',
    name: 'programme2',
    component: () => import('../views/programme2.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/trial',
    name: 'trial',
    component: () => import('../views/trial.vue')
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: () => import('../views/appointment.vue')
  },
  {
    path: '/hardware',
    name: 'hardware',
    component: () => import('../views/hardware.vue')
  },
  {
    path: '/yanfa',
    name: 'yanfa',
    component: () => import('../views/yanfa.vue')
  }


]

const router = new VueRouter({
  // mode: 'history', // 设置为 'history' 模式
  routes
})

export default router
