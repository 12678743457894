<template>
<!--标题组件， 中文标题+英文 标题-->
	<div>
		<div class="module-title">
			{{ title }}
		</div>
		<div class="module-title-en">
			{{ title_en }}
		</div>
	</div>

</template>

<script>
export default {
	name: "module_titlle",
	props:{
		title:{
			type:String,
			default:"标题1",
		},
		title_en:{
			type:String,
			default:"title",
		},

	},
}
</script>

<style scoped>
.module-title {
	font-size: 36px;
	font-weight: 600;
	color: #111111;

}
.module-title-en{
	font-size: 20px;
	font-weight: 500;
	color: #ACBCD8;
	text-transform: uppercase;
}

@media(max-width: 600px){
	.module-title {
		font-size: 24px;
	}
	.module-title-en{
		font-size: 14px;
	}
}
</style>
