
const configs = {

    yf:{
        favicon: require('@/assets/basic/favicon_yufu.png'),
        companyName: "遇服科技",
        companyLogo: require('@/assets/home/logo.png'),
        web_url:"https://e.yffrom.com",
        companyPhone:"185 1657 9165",
        comp_email:"service@iyourform.com",
        address:"上海奉贤区俊园路50号501室",
        contact_person_phone:"熊先生185 1657 9165      王女士186 2492 0613",
        companyContent_home:"    集S2B2C的PaaS低代码平台与多端应用于⼀体智能软硬件结合，提供全栈解决方案,集团连锁系统级养老科技公司。 \n" +
            "    成立于2019年上海市虹桥商务区，由知名互联网研发工程师联手养老行业原高层管理人员、养老科技系统资深专家、系统从业人员组成的专业养老科技公司。遇服科技从养老咨询、产品研发、运营流程优化和智能化数字化为一体，为养老客户提供一站式机构、居家和社区全场景的智慧养老综合解决方案。\n" +
            "    2023年底获知名上市公司悦心健康（002162）旗下公司数百万级的产业投资并达成全方位战略合作协议。目前系统平台已服务于悦心健康旗下养老机构，并在北京、上海、福建多家养老院(连锁集团)落地。",
        companyContent:"    集S2B2C的PaaS低代码平台与多端应用于⼀体智能软硬件结合，提供全栈解决方案,集团连锁系统级养老科技公司。 \n" +
            "    成立于2019年上海市虹桥商务区，由知名互联网研发工程师联手养老行业原高层管理人员、养老科技系统资深专家、系统从业人员组成的专业养老科技公司。遇服科技从养老咨询、产品研发、运营流程优化和智能化数字化为一体，为养老客户提供一站式机构、居家和社区全场景的智慧养老综合解决方案。\n" +
            "    2023年底获知名上市公司悦心健康（002162）旗下公司数百万级的产业投资并达成全方位战略合作协议。目前系统平台已服务于悦心健康旗下养老机构，并在北京、上海、福建多家养老院(连锁集团)落地。",
        wechat_gongzhong:require('@/assets/footer/wechat.png'),
        wechat_person:require('@/assets/footer/wx_person_yf.png'),
        ws:require('@/assets/footer/qr_ws.png'),
        tiktok:require('@/assets/footer/qr_dy.png'),
        icp_content:"© 2019-2024 yffroom.com 版权所有    沪ICP备20007014号",
    },
    anyang:{
        favicon: require('@/assets/basic/favicon_anyang.png'),
        companyName: "安养养老",
        companyLogo: require('@/assets/home/logo_anyang.png'),
        web_url:"http://jigou.yanglaoapp.com",
        companyPhone:"136 6144 5999",
        comp_email:"service@iyourform.com",
        address:"上海奉贤区俊园路50号501室",
        contact_person_phone:"吴先生136 6144 5999",
        companyContent_home:"     安养智慧养老管理系统，作为养老领域的革新力量，以科技为翼，为养老机构和居家养老场景量身定制卓越的管理方案。这一系统犹如一座数字化的养老城堡，从下而上的基础层、平台层、应用层架构稳固而有序。基础层如坚固基石，保障数据安全与系统稳定运行；平台层作为信息交互的中枢神经，高效整合各方数据，实现资源的优化调配；应用层则是面向养老服务的前沿阵地，功能丰富且实用。",
        companyContent: "       安养智慧养老管理系统，作为养老领域的革新力量，以科技为翼，为养老机构和居家养老场景量身定制卓越的管理方案。这一系统犹如一座数字化的养老城堡，从下而上的基础层、平台层、应用层架构稳固而有序。基础层如坚固基石，保障数据安全与系统稳定运行；平台层作为信息交互的中枢神经，高效整合各方数据，实现资源的优化调配；应用层则是面向养老服务的前沿阵地，功能丰富且实用。\n" +
            "       在居家养老板块，客户管理让每一位老人的信息清晰可查，构建起个性化服务的基础；呼叫中心成为老人与安全的紧密纽带，危急时刻一键响应；工单管理使服务流程规范透明，确保需求不遗漏；护理管理如同贴心的健康管家，为老人的日常护理和康复保驾护航。\n" +
            "       机构养老功能更是一应俱全。出入院管理简化流程，轻松完成床位安排、费用结算等环节；护理功能涵盖计划、排班、质量监控，为老人提供专业照护；后勤管理从物资到餐饮、设施维护，保障机构平稳运转；活动管理为老人带来丰富多彩的精神盛宴；数据可视化大屏宛如智慧之眼，为管理者呈现关键数据，助力科学决策。\n" +
            "       尤为瞩目的是，本系统永久免费使用，这一举措彰显了安养对养老事业的热忱与担当，致力于以科技赋能，让更多养老机构和老人享受到智慧养老带来的福祉，推动养老服务向着更高效、更优质、更人性化的方向大步迈进，开启智慧养老新时代。",
        wechat_gongzhong:require('@/assets/footer/wechat_anyang.jpg'),
        wechat_person:require('@/assets/footer/wx_person_yf.png'),
        ws:require('@/assets/footer/qr_ws.png'),
        tiktok:require('@/assets/footer/qr_dy.png'),
        icp_content:"© 2019-2024 yffroom.com 版权所有    沪ICP备20007014号",
    },



}

// 根据环境变量选择配置
const currentCompany = process.env.VUE_APP_COMPANY || "yf";

export default configs[currentCompany];


