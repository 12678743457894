<template>
<!--文字 卡片  标题+ 内容-->
	<div class="card-container padding">
		<div class="card-title">{{ title }}</div>
		<div class="card-text">{{ content }}</div>
		<div class="card-btn" @click="goTrial">{{ btn }}></div>
	</div>
</template>

<script>
import config from "@/config";

export default {
	name: "card-1",
	props:{
		title:{
			type:String,
			default:"标题",
		},
		content:{
			type:String,
			default:"标题",
		},
		btn:{
			type:String,
			default:"按钮",
		},
	},
	methods:{
		goTrial(){
			console.log(11)
			window.open(config.web_url, '_blank')
		},
	}
}
</script>

<style scoped>
@import "@/common/style/common.css";
.card-container {
	border-radius: 8px;
	background: #FFFFFF;
	box-sizing: border-box;
	border: 1px solid #EAECF3;
	max-width: 300px;
	height: 240px;
	margin: 16px;
}

.card-title { /* 机构养老平台 */
	margin-top: 24px;

	font-size: 24px;
	font-weight: 500;

	color: #333333;

}

.card-text {
	margin-top: 12px;

	font-size: 14px;
	font-weight: normal;
	line-height: 24px;
	letter-spacing: 0.06em;
	text-align: left;
	color: #999999;

}
.card-btn{
	color: var(--color-brand);
	text-align: right;
	cursor: pointer;
  margin-top: 40px;
}
.card-btn:hover{
	font-weight: 600;
}

@media(max-width: 600px){
	.card-container {
		max-width: 500px;
		margin: 16px 0;
		height: 240px;
	}
  .card-btn{
    color: var(--color-brand);
    text-align: right;
    cursor: pointer;
    margin-top: 40px;
  }
}
</style>
