<template>
	<div id="app">
		<div :class="{ 'shadow': isShadowVisible }" class="header">
			<div class="fw-b dflex-s">
				<img :src="companyLogo" alt="Vue logo" class="logo">
				<div class="margin-left-xs logo-name">{{ companyName }}</div>
			</div>

      <a-menu mode="horizontal" v-if="true" class="a-menu" :defaultSelectedKeys="defaultSelectedKeys">
        <a-menu-item key="">
          <router-link to="/">首页</router-link>
        </a-menu-item>
        <a-sub-menu>
           <span slot="title">解决方案 <a-icon type="down"  style="font-size: 12px"/></span>
          <a-menu-item key="jigou">
            <router-link to="/jigou">机构养老</router-link>
          </a-menu-item>
          <a-menu-item key="jujia">
            <router-link to="/jujia">居家养老</router-link>
          </a-menu-item>
          <a-menu-item key="hardware">
            <router-link to="/hardware">智慧硬件系统</router-link>
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="yanfa">
          <router-link to="/yanfa">研发实力</router-link>
        </a-menu-item>
        <a-menu-item key="price">
          <router-link to="/price">定价</router-link>
        </a-menu-item>
        <a-menu-item key="about">
          <router-link to="/about">关于</router-link>
        </a-menu-item>
        <a-menu-item key="appointment">
          <router-link to="/appointment">注册使用</router-link>
        </a-menu-item>
      </a-menu>

			<nav v-if="false">
				<div class="link-item">
					<router-link to="/">首页</router-link>
				</div>

        <a-dropdown class="link-item">
          <div class="link-item dflex-c">
            &nbsp; 解决方案 <img src="@/assets/common/arrow-down.svg">
          </div>
          <a-menu slot="overlay">
            <a-menu-item>
              <router-link to="/jujia">居家养老</router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link to="/jigou">机构养老</router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link to="/hardware">智慧硬件系统</router-link>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

        <div class="link-item">
          <router-link to="/yanfa">研发实力</router-link>
        </div>

				<div class="link-item">
					<router-link to="/price">定价</router-link>
				</div>
				<div class="link-item">
					<router-link to="/about">关于</router-link>
				</div>
        <div class="link-item">
          <router-link to="/appointment">注册使用</router-link>
        </div>

			</nav>
      <div class="menu" @click="switchMenu">
        <a-icon v-if="!showMenu" type="menu"/>
        <a-icon v-if="showMenu" type="close"/>
      </div>
			<div class="contact">
				<a-icon type="phone"/>
				{{ companyPhone }}
			</div>


		</div>

		<router-view/>
		<cus_footer_sec></cus_footer_sec>
		<cus_footer></cus_footer>

		<a-drawer
				:height="560"
				:visible="showMenu"
				:zIndex="998"
				placement="top"
				@close="onClose">
			<div style="height: 50px"></div>

      <a-menu mode="inline" @click="clickMenu" :defaultSelectedKeys="defaultSelectedKeys">
        <a-menu-item key="">
          <router-link to="/">首页</router-link>
        </a-menu-item>
        <a-sub-menu>
          <span slot="title">解决方案</span>
          <a-menu-item key="jigou">
            <router-link to="/jigou">机构养老</router-link>
          </a-menu-item>
          <a-menu-item key="jujia">
            <router-link to="/jujia">居家养老</router-link>
          </a-menu-item>
          <a-menu-item key="hardware">
            <router-link to="/hardware">智慧硬件系统</router-link>
          </a-menu-item>

        </a-sub-menu>
        <a-menu-item key="yanfa">
          <router-link to="/yanfa">研发实力</router-link>
        </a-menu-item>
        <a-menu-item key="price">
          <router-link to="/price">定价</router-link>
        </a-menu-item>
        <a-menu-item key="about">
          <router-link to="/about">关于</router-link>
        </a-menu-item>
        <a-menu-item key="appointment">
          <router-link to="/appointment">注册使用</router-link>
        </a-menu-item>
      </a-menu>

      <div v-if="false">
        <div class="draw-menu-item " @click="goMenu('/')">首页</div>
        <div class="draw-menu-item " @click="goMenu('/jujia')">居家养老</div>
        <div class="draw-menu-item " @click="goMenu('/jigou')">机构养老</div>
        <div class="draw-menu-item " @click="goMenu('/yanfa')">研发实力</div>
        <div class="draw-menu-item " @click="goMenu('/price')">定价</div>
        <div class="draw-menu-item " @click="goMenu('/about')">关于</div>
        <div class="draw-menu-item " @click="goMenu('/appointment')">注册使用</div>
      </div>

		</a-drawer>

		<a-back-top/>
	</div>
</template>

<script>
import Cus_footer from "@/components/cus_footer.vue";
import Cus_footer_sec from "@/components/cus_footer_sec.vue";
import config from "@/config";

export default {
	name: 'app',
	components: {Cus_footer_sec, Cus_footer},
	data() {
		return {
			companyName: config.companyName,
			companyLogo: config.companyLogo,
			companyPhone: config.companyPhone,
			isShadowVisible: false, // 控制阴影是否可见的变量

			showMenu: false,// 移动端 - 当前展示菜单

      defaultSelectedKeys:[],
		}
	},
  created() {
    let link = window.location.hash.split('/')
    console.log("window",link)
    this.defaultSelectedKeys = [link[1]] || []
  },
  mounted() {
		// window.addEventListener("scroll", this.handleScroll);


	},
	beforeDestroy() {
		// window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleScroll() {
			// 获取滚动容器的滚动位置
			console.log(111)

			// 根据滚动位置更新阴影可见性
			this.isShadowVisible = window.scrollY > 100;
		},
		switchMenu() {
			this.showMenu = !this.showMenu
		},

		onClose() {
			this.showMenu = false
		},

		goMenu(link) {
			this.$router.push(link)
			this.showMenu =false
		},

    clickMenu(item){
      console.log(item)
      this.showMenu =false
    },
	},
}
</script>

<style scoped>
.ant-menu-item, .ant-menu-submenu-title{
  padding: 0 30px;
}
.ant-menu-horizontal{
  border-bottom: none;
  line-height: 68px!important;
}
/deep/ .ant-drawer-body{
  padding: 24px 12px;
}
/deep/ .ant-menu-horizontal {
  border-bottom: none;
}
/deep/ .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected{
  border-bottom-color: #FFFFFF;
}

.ant-menu-horizontal > .ant-menu-item > a{
  font-weight: 500;
}


.ant-menu {
  font-weight: 500;
}

.ant-menu-item, .ant-menu-submenu-title{
  transition: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #FFFFFF;
}
.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after{
  display: none;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
  border: none;
}
</style>

<style scoped >
@import "@/common/style/common.css";
@import "@/common/style/color.scss";

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	background: #F6FAFF;
	width: 100%;
}

nav {
	color: #111111;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

a {
	color: #111111;
}

nav a.router-link-exact-active {
	color: var(--color-brand);
}

.menu {
	display: none;
	cursor: pointer;
}
.menu:hover{
  color: var(--color-brand);
}
.header {
	/*width: 100%;*/
	height: 72px;
	background: #ffffff;
	padding: 0 16px;

	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0; /* 当滚动到顶部时粘住 */
	z-index: 999;
}

.logo {
	width: 48px;
	/*height: 36px;*/
}

.logo-name {
	font-size: 24px;
}

.shadow {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06); /* 添加阴影效果 */
}

.link-item {
	font-size: 16px;
	margin-right: 30px;
	width: 100px;
	cursor: pointer;
  font-weight: 500;
}

.link-item :hover {
  color: var(--color-brand);
}

.contact {
	font-size: 18px;
	font-weight: 500;
	color: #111111;
}

.draw-menu-item {
	/*text-align: center;*/
	font-size: 16px;
	color: #111111;
	height: 60px;
	line-height: 60px;
	border-width: 0 0 1px 0;
	border-color: #eeeeee;
	border-style: solid;
	cursor: pointer;
}

.draw-menu-item:hover {
	color: var(--color-brand);
}

@media (max-width: 600px) {
	.header {
		height: 48px;
	}

	.logo {
		width: 36px;
		/*height: 36px;*/
	}

	.logo-name {
		font-size: 16px;
	}

	nav {
		display: none;
	}

	.contact {
		display: none;
	}

	.menu {
		display: block;
	}

  .a-menu{
    display: none;
  }

}
</style>
