<template>
	<div class="banner-container dflex-c"  :style="bannerStyle">
		<div class="banner-content">
			<div class="banner-title-1">{{ title1 }}</div>
			<div class="banner-title-2 margin-top">{{ title2 }}</div>
			<div class="dflex-s" v-if="btn_text">
				<cus_btn :text="btn_text" class="margin-top" @click="goTrial" ></cus_btn>
			</div>
			<div class="banner-tip margin-top-xs">{{ tip }}</div>
		</div>
	</div>
</template>

<script>
import Cus_btn from "@/components/cus_btn.vue";
import config from "@/config";

export default {
	name: "banner",
	components: {Cus_btn},
	props:{
		title1:{
			type:String,
			default:"标题1",
		},
		title2:{
			type:String,
			default:"标题2",
		},
		tip:{
			type:String,
			default:"",
		},
		btn_text:{
			type:String,
			default:"按钮",
		},
		img_url:{
			type:String,
			default:"",
		},
	},
	computed: {
		bannerStyle() {
			return {
				backgroundImage: `url(${this.img_url})`,
			};
		},
	},
	methods:{
		goTrial(){
			window.open(config.web_url, '_blank')
		},
	}
}
</script>

<style scoped>
@import "@/common/style/common.css";
.banner-container {
	position: relative;
	text-align: left;
	height: 500px;

	/*background-image: url("../assets/home/banner_back_home.png") ;*/
	background-position: center; /* 居中 */
	background-repeat: no-repeat; /* 不重复 */
	background-size: cover; /* 图片按原始比例显示，不缩放 */

	padding: 0 20px;
}
.banner-content{
	width: 100%;
	max-width: 1200px;
}
.banner-title-1 {
	font-size: 36px;
	font-weight: 600;
	color: #3D3D3D;
}

.banner-title-2 { /* 为养老服务提供专业、智能的解决方案，致力于打造企业级养老服务新标杆 */
	font-size: 16px;
	color: #3D3D3D;
  white-space: pre-wrap;
}
.banner-tip{
	font-size: 14px;
	color: #999999;
  margin-top: 12px;
  white-space: pre-wrap;
}

@media(max-width: 600px){
	.banner-container{
		height: 300px;
	}
	.banner-title-1 {
		font-size: 24px;
		font-weight: 600;
	}
	.banner-title-2 { /* 为养老服务提供专业、智能的解决方案，致力于打造企业级养老服务新标杆 */
		font-size: 14px;
	}
  .banner-tip{
    font-size: 12px;
  }
}

</style>
