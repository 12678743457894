<template>

	<div @click="click">
		<div class="button1 dflex-c" >{{text}}</div>
	</div>

</template>

<script>
export default {
	name: "cus_btn",
	props:{
		text:{
			type:String,
			default:"按钮",
		}
	},
	methods:{
		click(){
			this.$emit('click')
		},
	},

}
</script>

<style scoped>
.button1 {
	border-radius: 4px;
	padding: 8px 16px;
	color: #FFFFFF;
	background: var(--color-brand);
	cursor: pointer;
}
.button1:hover{
	background: #2450e5;
	transition: ease-in-out;
}

</style>
