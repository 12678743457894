<template>
	<div>
		<banner :title1="banner.title1" :title2="banner.title2" btn_text="免费使用" :tip="banner.tip" :img_url="banner.img_url "></banner>

		<module :title="module1.title" :title_en="module1.title_en">
			<div class="module-content-home2 margin-top-xl">
				<div v-for="(item,index) in module1.content" class="home2-item">
					<img :src="item.img" class="icon1" height="120" width="120"/>
					<div class="fw-b fs-18">{{ item.title }}</div>
					<div class="f-c-grey margin-top">{{ item.text }}</div>
				</div>
			</div>
		</module>

		<module :title="module2.title" :title_en="module2.title_en" :bg_type="2">
			<div  class="margin-top-xxl home3-module">
				<div  v-for="(item,index) in module2.content">
					<card2 :title="item.title" :content="item.text" :img="item.img"></card2>
				</div>
			</div>
		</module>

		<module title="赋能养老产业，创造无限平台价值" title_en="Create infinite platform value">
			<div  class="margin-top-xxl home3-module">
				<div  v-for="(item,index) in module_funeng.content">
					<card1 :title="item.title" :content="item.text" btn="免费使用"></card1>
				</div>
			</div>
		</module>

		<module :bg_type="2" title="关于我们" title_en="about us">
			<div class="dflex-s-s margin-top-xl about-container" style="max-width: 900px">
				<img class="about-pic" src="../assets/home/picture4.png"/>
				<div class="about-content">
          {{content}}</div>
			</div>
		</module>

	</div>

</template>

<script>
import Cus_btn from "@/components/cus_btn.vue";
import Banner from "@/components/banner.vue";
import Module_titlle from "@/components/module_titlle.vue";
import Module from "@/components/module.vue";
import Card1 from "@/components/card-1.vue";
import Card2 from "@/components/card-2.vue";
import config from "@/config";

export default {
	name: 'home',
	data() {
		return {
			banner: {
				title1: "智慧养老 - 让养老生活更美好",
				title2: "为养老服务提供专业、智能的解决方案，致力于打造企业级养老服务新标杆",
        tip:"·全网首家    ·免费使用    ·具体见[定价]",
				img_url:require('@/assets/home/banner_back_home.png'),
			},
			module1: {
				title: "三项力，助力智慧养老快速转型",
				title_en:"Rapid Transformation",
				content: [
					{
						title: "产品专注力",
						text: "产品迭代速度远超竞品完整覆盖机构养老全流程",
						img: require('../assets/home/icon2.png')
					},
					{
						title: "服务卓越力",
						text: "快速响应客户需求应用场景7x18 小时陪伴式咨询服务",
						img: require('../assets/home/icon1.png')
					},
					{
						title: "开放拓展力",
						text: "自主研发paas平台响应业务变化完善培训指导及运维服务",
						img: require('../assets/home/icon3.png')
					}
				],
			},
			module2: {
				title: "多场景智慧养老解决方案",
				title_en:"Multi scenario care solution",
				content: [
					{
						title: "机构养老平台",
						text: "从机构养老的实际管理和服务需求出发，提供全方位的解决方案，满足从入院到退住全流程服务，确保您的养老机构运营顺畅，为长者的生活品质保驾护航。",
						img: require('../assets/home/jiejuefangan_pic_1.png')
					},
					{
						title: "居家养老平台",
						text: "我们致力于打造以家庭为核心、社区养老服务网络为支撑的全方位居家养老服务体系。为您提供更安全、便捷和舒适的养老服务体验，让您的晚年生活更加安心和美好。",
						img: require('../assets/home/jiejuefangan_pic_2.png')
					},
					{
						title: "医养结合系统",
						text: "以机构养老的实际管理和服务需求为出发点，我们融合现代医疗技术，为您的养老服务注入医疗功能模块，让您的养老机构成为长者安心、舒适的健康之家。",
						img: require('../assets/home/jiejuefangan_pic_3.png')
					}
				],
			},

			module_funeng:{
				content: [
					{
						title: "个性化服务匹配",
						text: "根据长者的需求和偏好，提供定制化的居家或机构养老服务，确保每一位长者都能得到最适合自己的照护。",
					},
					{
						title: "智能化监控与预警",
						text: "通过实时监测与数据分析，对长者的健康状况进行跟踪，及时发出预警，为长者的安全保驾护航。",
					},
					{
						title: "医养结合系统",
						text: "平台整合各类养老服务资源，包括医疗、康复、家政等，提高服务效率，让长者享受到更优质、高效的服务。",
					},

				],
			},

      content: config.companyContent_home,
		}
	},
	components: {Card2, Card1, Module, Module_titlle, Banner, Cus_btn}
}
</script>


<style scoped>
@import "@/common/style/common.css";


.icon1 { /* 图标-home */

	width: 140px;
	height: 140px;
}

.module-content-home2 {
	width: 100%;
	max-width: 900px;
	/*height: 286px;*/
	text-align: center;
	display: flex;
	justify-content: space-between;
}
.home3-module{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.about-pic { /* 矩形 110 */
	min-width: 180px;
	width: 13.75vw;
	height: 226px;
}


.about-container{
	flex-wrap: wrap;
}
.about-content {
	font-size: 16px;
	font-weight: normal;
	line-height: 36px;
	color: #666666;
	text-align: justify;
	flex: 300px;
	margin-left: 24px;
  white-space: pre-wrap; /* 保留空白字符并换行 */
}
.home2-item{
	max-width: 200px;
}


@media(max-width: 600px){
	.about-container{
		flex-wrap: wrap;
		display: block;
	}
	.about-pic { /* 矩形 110 */
		min-width: 180px;
		width: 100%;
		height: 226px;
	}
	.about-content{
		margin-left:0;
		margin-top: 12px;
	}
	.module-content-home2 {
		display: block;
	}
	.home2-item{
		max-width: 100%;
	}
}

</style>
