<template>
<!--文字 卡片  图片 + 标题+ 内容-->
	<div class="card-container" :style="{height:card_height+'px'}">
		<img class="picture" :src="img"/>
		<div class="padding">
			<div class="card-title">{{ title }}</div>
			<div class="card-text">{{ content }}</div>
		</div>

<!--		<div class="card-btn margin-top-big">{{ btn }}></div>-->
	</div>
</template>

<script>
export default {
	name: "card-2",
	props:{
		img:{
			type:String,
			default:"",
		},
		title:{
			type:String,
			default:"标题",
		},
		content:{
			type:String,
			default:"标题",
		},
		btn:{
			type:String,
			default:"按钮",
		},
		card_height:{
			type:Number,
			default:400
		},
	},
}
</script>

<style scoped>
@import "@/common/style/common.css";
.card-container {
	box-sizing: border-box;
	border-radius: 4px;
	background: #FFFFFF;
	box-shadow: 0px 4px 10px 0px rgba(7, 24, 47, 0.1);
	/*padding: 16px;*/
	max-width: 300px;
	margin: 16px;
}

.card-title { /* 机构养老平台 */
	/*margin-top: 24px;*/
	/*text-align: left;*/
	font-size: 18px;
	font-weight: 500;
	color: #333333;

}

.card-text {
	margin-top: 12px;

	font-size: 14px;
	font-weight: normal;
	line-height: 24px;
	letter-spacing: 0.06em;
	text-align: left;
	color: #999999;

}
.card-btn{
	color: #1890ff;
	text-align: right;
}

.picture{
	width: 100%;
	height: 230px;
	object-fit: cover;
}

@media(max-width: 600px){
	.card-container {
		margin: 16px 0;
		max-width: 500px;
	}
}
</style>
