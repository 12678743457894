<template>
<!--模块-->
	<div class="module" :style="bgStyle">
		<module_titlle :title="title" :title_en="title_en"></module_titlle>
		<slot></slot>
	</div>
</template>

<script>
import Module_titlle from "@/components/module_titlle.vue";

export default {
	name: "module",
	components: {Module_titlle},
	props:{
		bg_type:{
			type:Number,
			default:1, //背景颜色 1白色 2浅蓝色
		},
		title:{
			type:String,
			default:"标题1",
		},
		title_en:{
			type:String,
			default:"title",
		},
	},
	computed: {
		bgStyle() {
			return {
				backgroundColor: this.bg_type === 1?'#ffffff':'#F6FAFF',
			};
		},
	},
}
</script>

<style scoped>

.module{
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 56px;
}

@media(max-width: 600px){
	.module{
		padding: 40px 12px;
	}
}
</style>
