<template>
	<div class="banner-container dflex-c " >
		<div class="banner-content dflex-c dflex-col">
			<div class="banner-title-1">{{ title1 }}</div>
			<div class="banner-title-2 margin-top">{{ title2 }}</div>
			<div class="dflex-s margin-top-xxl">
				<div class="btn-outline" @click="goTrial">{{btn_text}}</div>
			</div>

		</div>

	</div>
</template>

<script>


export default {
	name: "cus_footer_sec",
	components: {},
	props:{
		title1:{
			type:String,
			default:"智慧养老数字化管理平台",
		},
		title2:{
			type:String,
			default:"让长者贴心、更让家属放心",
		},

		btn_text:{
			type:String,
			default:"免费使用",
		},
		img_url:{
			type:String,
			default:"require('@/assets/footer/footer_back_sec.png')",
		},
	},
	computed: {
		bannerStyle() {
			return {
				backgroundImage: `url('@/assets/footer/footer_back_sec.png')`,
			};
		},
	},
	methods:{
		goTrial(){
			console.log(11)
			let url = 'https://e.yffrom.com'
			window.open(url, '_blank')
		},
	}
}
</script>

<style scoped>
@import "@/common/style/common.css";
.banner-container {
	position: relative;
	text-align: left;
	height: 280px;
	/*background: #F6FAFF;*/

	background-image: url("../assets/footer/footer_back_sec.png") ;
	background-position: center; /* 居中 */
	background-repeat: no-repeat; /* 不重复 */
	background-size:cover; /* 图片按原始比例显示，不缩放 */
}
.banner-content{
	width: 1200px;
}
.banner-title-1 {
	font-size: 36px;
	font-weight: 600;
	color: #ffffff;
}

.banner-title-2 {
	font-size: 16px;
	color: #ffffff;
}
.btn-outline{

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 4px;
	padding: 8px 32px;
	color: white;
	box-sizing: border-box;
	border: 1px solid #FFFFFF;

	cursor: pointer;

}
.btn-outline:hover{
	background: rgba(255, 255, 255, 0.1);
}

@media(max-width: 600px){
	.banner-title-1 {
		font-size: 24px;
	}
	.banner-title-2 {
		font-size: 14px;
	}
}
</style>
