<template>
	<div class="footer-container dflex-c">
		<div class="footer-content">
			<div class="footer-up-content">
				<div class="" style="margin-right: 40px;margin-top: 16px;">
					<div class="footer-header">方案案例</div>
					<div class="footer-text">获取方案</div>
					<div class="footer-text">演示系统</div>
					<div class="footer-text">上门体验</div>
					<div class="footer-text">专业服务</div>
				</div>
				<div style="margin-right: 40px;margin-top: 16px;">
					<div class="footer-header">联系我们</div>
					<div class="footer-text">联系电话：{{ companyPhone }}</div>
					<div class="footer-text">联系邮箱：{{ email }}</div>
					<div class="footer-text">公司地址：{{ address }}</div>
				</div>
				<div class="flex1" style="margin-right: 40px;margin-top: 16px;">
					<div class="footer-header">媒体账号</div>
					<div class="footer-text">
						关注我们的媒体账号，获取有关我们的品牌和康养知识，产品服务优惠，客户成功案例的最新信息。
					</div>
					<div class="dflex-s margin-top">
						<a-popover >
							<template slot="content">
								<div>
									<img class="footer-qr-2 margin-top-xs" :src="qr_ws"/>
									<div class="tac fs-12 margin-top-xs">微信扫码 关注视频号</div>
								</div>
							</template>
							<img class="footer-img-media" src="../assets/footer/wechat_video.png">
						</a-popover>
						<a-popover >
							<template slot="content">
								<div>
									<img class="footer-qr-2 margin-top-xs" :src="qr_tiktok"/>
									<div class="tac fs-12 margin-top-xs">抖音扫码 关注抖音号</div>
								</div>
							</template>
							<img class="footer-img-media" src="../assets/footer/ticktok.png">
						</a-popover>

						<!--							<a-popover >-->
						<!--								<template slot="content">-->
						<!--									<p> todo此处放置二维码</p>-->
						<!--								</template>-->
						<!--								<img class="footer-img-media" src="../assets/footer/kuaishou.png">-->
						<!--							</a-popover>-->

					</div>
				</div>
				<div  class="footer-up-pic">
					<div class="footer-header">关注我们</div>
          <div class="dflex-s">
            <div class="margin-right tac">
              <img class="footer-qr" :src="pic_wechat_gongzhong"/>
              <div class="footer-text">微信公众号</div>
            </div>
            <div class="tac">
              <img class="footer-qr" :src="pic_wechat_person"/>
              <div class="footer-text">加微信号码</div>
            </div>
          </div>

				</div>
			</div>
			<a-divider></a-divider>
			<div class="footer-web-info">
				{{icp_content}}
			</div>
		</div>
	</div>
</template>

<script>
import config from "@/config";
export default {
	name: "cus_footer",
	computed:{
	},
	data(){
		return{
			pic_wechat_gongzhong:config.wechat_gongzhong,
      pic_wechat_person:config.wechat_person,
			companyPhone:config.companyPhone,
			email:config.comp_email,
			address:config.address,
			qr_ws:config.ws,
			qr_tiktok:config.tiktok,
      icp_content: config.icp_content,
		}
	},
}
</script>

<style scoped>
@import "@/common/style/common.css";

/deep/ .ant-divider-horizontal {
	background-color: #323B58;
	margin: 12px 0;
}

.footer-container {
	padding: 40px 40px 16px 40px ;
	text-align: left;
	background: #0C1637;
}
.footer-content{
	max-width: 1200px;
}

.footer-up-content{
	display        : flex;
	justify-content: space-between;
	align-items    : flex-start;
}

.footer-up-pic{

}
.footer-header {
	font-size: 14px;
	font-weight: 500;
	line-height: 36px;
	color: #FFFFFF;
}

.footer-text {
	font-size: 14px;
	font-weight: normal;
	line-height: 28px;
	color: #999999;
}

.footer-qr {
	width: 102px;
	height: 102px;
}
.footer-qr-2 {
	width: 140px;
	height: 140px;
}

.footer-web-info {
	font-size: 14px;
	font-weight: normal;
	line-height: normal;
	color: #666666;
  white-space: pre-wrap; /* 保留空白字符并换行 */
}

.footer-img-media {
	width: 40px;
	height: 40px;
	margin-right: 12px;
}

@media(max-width: 600px){

	.footer-container {
		padding: 40px 20px 16px 20px ;
	}

	.footer-up-content{
		display        : block;
	}

}
</style>
