import Vue from 'vue'
import App from './App.vue'
import router from './router'
import config from "@/config";
Vue.config.productionTip = false
// import less from 'less'
// Vue.use(less)

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);

// 动态设置 favicon
const setFavicon = (url) => {
  let link = document.querySelector("link[rel='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.head.appendChild(link);
  }
  link.href = url;
};

setFavicon(config.favicon);



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
