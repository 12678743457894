import { render, staticRenderFns } from "./cus_footer_sec.vue?vue&type=template&id=627ffd48&scoped=true"
import script from "./cus_footer_sec.vue?vue&type=script&lang=js"
export * from "./cus_footer_sec.vue?vue&type=script&lang=js"
import style0 from "./cus_footer_sec.vue?vue&type=style&index=0&id=627ffd48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "627ffd48",
  null
  
)

export default component.exports